.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.helpCenter {
  color: #D32028;
  font-size: 20px;

}

.contactDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-left: 1em;
  margin-right: 1em;
  font-size: 1.3rem;
}

.contactItem {
  display: flex;
  gap: 0.4rem;
  align-self: center;
  justify-content: center;
}
@media screen and (max-width: 786px) {
  .contactDiv {
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-left: 1em;
    margin-right: 1em;
    font-size: 1.3rem;
  }
  .helpCenter {
    font-size: 14px;
  
  }
}

.copyrightText {
  /* padding: 2rem 0 1rem 0; */
  font-size: 1em;

  text-align: center;
}


/* modifications */

@media screen and (max-width: 600px) {
  .copyrightText {
    padding: 10px; 
    font-size: 0.7em;
    text-align: center;
  }
}

.contactNumber {
  font-size:14px;
  line-height: 1;

}

@media screen and (max-width: 600px) {
  .contactNumber {
    font-size:12px;

  }
}

.PhoneIconImage {
  width: 16px;
  height: 16px;
}


@media screen and (max-width: 600px){
.PhoneIconImage {
  width: 14px;
  height: 14px;
}
}


.mailText{
  font-size:16px;
  line-height: 1;
}

@media screen and (max-width: 600px) {
  .mailText{
    font-size:14px;
  }
}

.mailIconImage{
  width: 16px;
  height: 16px;

}

@media screen and (max-width: 600px){
  .mailIconImage{
    width: 14px;
    height: 14px;
  }
}

