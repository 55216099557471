@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.body {
  background: url("../../../images/hero_bg.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.body * {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;

}

.backdrop {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (max-width: 786px) {
  .backdrop {    
    height: 100%;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    min-height: 100vh;
  }
}


.container {
  background-color: #ffffff;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin: auto;
  border: 1px solid rgba(212, 33, 41, 0.15);
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
  padding: 25px 12px 18px 12px;
  font-family: 'Outfit';

}
@media screen and (max-width: 786px) {
  .container {
    width: 90%;
  }
}
.content {
  /* margin: 1rem; */
  /* border: 1px solid #d32028; */
  border-radius: 10px;
  width: 90%;
}
.consentText{
  font-size: 14px;
}

.side {
  flex: 1;
  background: url("../../../images/hero_bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
}

.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 8rem);
  padding: 4rem 8rem;
}
.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* min-width: 40rem;
  max-width: 90vh; */
}
.headerText {
  text-align: center;
  color: var(--label-black);
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: var(--primary-color);
}
.headerLabelText {
  text-align: start;
  color: var(--label-black);
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}

