.body {
  background: url("../../../images/hero_bg.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  border: 1px solid #D4212926;
  box-shadow: 4px 0px 4px 0px #0000000F;
}

@media screen and (max-width:768px) {
  .container{
    width: 90%;
  }
}

.main {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.Header {
  display: inline-flex;
  justify-content: space-between;
}
