.table-of-contents {
  width: 100%;

  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 4px -2px var(--label-black) 0f;

  box-shadow: 0px 4px 8px -2px var(--label-black) 1a;
}

.table-of-contents th,
.table-of-contents td {
  border: 1px solid #ddd;
  padding: 10px;
}

.table-of-contents.mobile th,
.table-of-contents.mobile td {
  font-family: "Outfit-Medium";
  font-size: 12px;
}

.table-of-contents.desktop th,
.table-of-contents.desktop td {
  font-family: "Outfit-Medium";
  font-size: 14px;
}

.tables {
  box-shadow: 0px 2px 4px -2px var(--label-black) 0f;

  box-shadow: 0px 4px 8px -2px var(--label-black) 1a;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  margin-top: 10px;
}
