.body {
    background: url("../../../images/hero_bg.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
  
  .body * {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 50px;
    box-shadow: 4px 4px 10px 4px #0000000F;
    padding: 20px 12px 10px 12px;
    width: 600px;
    border: 1px solid rgba(212, 33, 41, 0.15);
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);


  }
  @media screen and (max-width:990px) {
    .container{
      width: 70%;
    }
    
  }
  @media screen and (max-width:768px) {
    .container{
      width: 80%;
    }
    
  }
 .title{
   background: #fff3f3;
    border-radius: 12px 12px 0px 0px;
    padding-left:20px;
    padding-top:15px;
    padding-bottom:1px
 }
 .titleText{
  font-size: 18px;
  font-weight: bold;
 }
.formContainer{
  margin-top: 20px;
}
.card1{
  border-radius: 10px;
  border: 1px solid rgba(212, 33, 41, 0.15);
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 15px;
}



  .side {
    flex: 1;
    background: url("../../../images/hero_bg.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 0px 30px 30px 0px;
  }
  .backdrop {
    min-height:  100vh;
  }
  /* @media screen and (max-width: 786px) {
    .backdrop {
      height: 100%;
      width: 100%;
      padding-left: 1%;
      padding-right: 1%;
      min-height: 100vh;
    }
  } */
  .sideContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 8rem);
    padding: 4rem 8rem;
  }
  .main {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* min-width: 40rem;
      max-width: 90vh; */
  }
  .headerText {
    text-align: center;
    color: var(--label-black);
    font-size: 1.5rem;
  }
  .headerNextText {
    text-align: center;
    color: var(--primary-color);
  }
  .headerLabelText {
    text-align: start;
    color: var(--label-black);
    font-size: 1.3rem;
    margin-top: 1rem;
  }
  .nextStepsCard {
    border: 1px solid var(--primary-border);
    border-radius: 12px;
    padding: 2rem 1rem;
  }
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 3;
  }
  .logoContainer p {
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
  }
  .footer {
    flex: 1;
    display: flex;
    /* flex-direction: column; */
    align-items: end;
  }
  .mainContainer {
    padding: 8rem;
  }
  .secondaryImage {
    position: fixed;
    bottom: 0;
    right: 0;
  }
  
  .textTitle {
    color: var(--primary-color);
    font-size: 2rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .textDesc {
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
  }
  .content {
    width: 95%;
    margin-bottom: 12px;
    
    
  }
  .addressInputGrid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
 
  }
  .addressInputGrid2{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem;
  }
  
