.fm-container{
    width: 100%;
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
}
span{
    display: flex;
    align-items: center;
    gap:1;
}
p{
    font-family: 'Outfit';
    font-style: 14px;
    line-height: 2;
}
.label{
    font-family: "Outfit";
    font-weight: 500;
    margin-right: 10px;
}
.value{
    font-family: "Outfit";
}
