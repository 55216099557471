@font-face {
  font-family: Inter-Bold;
  src: url("./Assets/fontfamily/Inter-Bold.ttf");
}
@font-face {
  font-family: Inter-Medium;
  src: url("./Assets/fontfamily/Inter-Medium.ttf");
}
@font-face {
  font-family: Inter-Regular;
  src: url("./Assets/fontfamily/Inter-Regular.ttf");
}
@font-face {
  font-family: Inter-SemiBold;
  src: url("./Assets/fontfamily/Inter-SemiBold.ttf");
}

@font-face {
  font-family: Outfit-Bold;
  src: url("./Assets/fontfamily/Outfit-Bold.ttf");
}
@font-face {
  font-family: Outfit-Light;
  src: url("./Assets/fontfamily/Outfit-Light.ttf");
}
@font-face {
  font-family: Outfit-Medium;
  src: url("./Assets/fontfamily/Outfit-Medium.ttf");
}
@font-face {
  font-family: Outfit-Regular;
  src: url("./Assets/fontfamily/Outfit-Regular.ttf");
}
@font-face {
  font-family: Outfit-SemiBold;
  src: url("./Assets/fontfamily/Outfit-SemiBold.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

* {
  /* --primary-color: #d32028; */
  /* --primary-background-transparent: rgba(255, 242, 238, 0.94); */
  /* --primary-border: rgba(212, 33, 41, 0.15); */
  /* --box-background: #fffcfa; */
  /* --box-background-dark: #fff7f2; */
  /* --primary-border-dark: #f9d8d6; */
  /* --primary-border-shadow:rgba(211, 32, 40, 0.1); */
  --primary-color: #2c6ce3;
  --primary-background-transparent: #d7dfe9e6;
  --primary-background: #fff8f4;
  --background-transparent-white: rgba(255, 255, 255, 0.95);
  --label-black: #101828;
  --white: #ffffff;
  --primary-border-shadow: rgba(44, 108, 227, 0.1);

  --primary-border-dark: #b6d0ff;
  --primary-border: rgba(44, 108, 227, 0.15);
  --box-background: #2c6ce314;
  --box-background-dark: #2c6ce340;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "Outfit", sans-serif;
}

#root {
  min-height: 100vh;
}
