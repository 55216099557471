.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  height: 4rem;
}
@media screen and (max-width: 767px) {
  .logo {
    height: 3rem;
  }
}

.downloadButton {
  display: flex;
  background: var(--primary-color);
  border-radius: 60px;
  /* width: 9rem; */
  /* padding-left: 15px; */
  padding: 10px 15px;
  color: white;

  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .downloadButton {
    display: flex;
    background: var(--primary-color);
    border-radius: 60px;
    /* width: 9rem; */
    padding-left: 15px;
    padding: 8px;
    height: 2.5rem;
    color: white;

    cursor: pointer;
  }
}

.downloadButton p {
  padding: 0;
  margin: 0;
}

.footerRight {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-left: 0.5rem;
  margin: 5px;
}
