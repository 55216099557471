.body {
  min-height: 100vh;
  background: url("../../images/hero_bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  border-radius: 10px;
  padding: 30px 18px 0px 18px;
  flex-direction: column;
  margin: 0 auto;
  width: 400px;
  border: 1px solid rgba(212, 33, 41, 0.15);
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
}

.side {
  flex: 1;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
}
.backdrop {
  background: "#fff3f3";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
@media screen and (max-width:768px){
  .container{
    width: 90%
  }
}
.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 8rem);
  padding: 4rem 8rem;
}
.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* min-width: 40rem;
	max-width: 90vh; */
}
.headerText {
  text-align: center;
  color: var(--label-black);
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: var(--primary-color);
}
.headerLabelText {
  text-align: start;
  color: var(--label-black);
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
.content {
  padding: 2rem;
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 30px;
}
.footerText {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  font-style: 14px;
}

.footerImage {
  height: 1.5rem;
}

.buttonList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputField {
  width: calc(100% - 20px);
  margin: 10px;
}
.inputField > div {
  width: 80%;
  margin: 0 10%;
}
label {
  width: 80%;
  margin: 0 10%;
  margin-top: 10px;
}

.childContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  overflow-y: scroll;
  width: 70%;
}


.sanctionNextArrow {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}
.sanctionsListGrid {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  gap: 1rem;
}
.sanctionsGreenTick {
  height: 2rem;
  width: 2rem;
  margin-left: 5px;
}
.sanctionsProgressBar {
  height: 3rem;
  width: 4px;
  background-color: #40b64b;
  margin: 0 auto;
}
.sanctionsProgressBarText {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #000000;
}
.sanctionsProgressBarGrey {
  height: 2rem;
  width: 4px;
  background-color: #d3d3d3;
  margin: 0 auto;
}

