.body {
  min-height: 100vh;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  background: var(--white);
  border-radius: 30px;
  padding: 1rem;
  margin: 2rem;
  height: calc(100% - 19rem);
  flex-direction: column;
  gap: 2rem;
  /* width: 50%; */
  /* background-color: var(--background-transparent-white); */
  overflow-y: auto;
}
.backdrop {
  /* position: fixed; */
  background: var(--primary-background-transparent);
  height: 100%;
  width: 100%;
  min-height: 100vh;
}
/* .body {
  background: url("../../images/background.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-between;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: var(--background-transparent-white);
} */

.main {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.headerText {
  text-align: center;
  color: var(--label-black);
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: var(--primary-color);
}
.headerLabelText {
  text-align: start;
  color: var(--label-black);
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.tabInactive {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-background-transparent);
  box-shadow: 0px 3px 3px rgba(211, 32, 40, 0.1);
  border-radius: 12px;
  padding: 1rem 2rem;
  cursor: pointer;
}

.courseDetailsTabs {
  display: flex;
  gap: 3rem;
  width: 75%;
}

.tabActive img,
.tabInactive img {
  height: 5rem;
  margin-right: 2rem;
}

.tabActive p,
.tabInactive p {
  font-size: 1.5rem;
  color: #646464;
}

.tabActive {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-background-transparent);
  border: 2px solid var(--primary-color);
  box-shadow: 0px 3px 3px rgba(211, 32, 40, 0.1);
  border-radius: 12px;
  padding: 1rem 2rem;
  cursor: pointer;
}

.addressInputGrid {
  display: grid;
  grid-template-areas:
    "door door street street"
    "city state pin save";
  gap: 1rem;
}
