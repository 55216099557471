.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-background-transparent);
  max-width: 450px;
  padding: 1rem;
  border-radius: 1rem;
  min-height: 450px;
  box-shadow: 0 1rem 1rem #00000040;
  margin-left: 020vh;
  margin-right: 020vh;
  margin-top: 02vh;
  margin-bottom: 02vh;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container h1,
.container p {
  text-align: center;
}

/* button {
  width: 80%;
  margin: 0 10%;
  margin-top: 50px;
} */

.inputField {
  width: 100%;
  margin: 10px;
}
.inputField > div {
  width: 80%;
  margin: 0 10%;
}
/* label {
  width: 80%;
  margin: 0 10%;
  margin-top: 10px;
} */
.image {
  height: 5rem;
}

.container button {
  margin-top: 50px;
}

.footerText {
  margin-top: 3rem;
  display: flex;
  align-items: center;
}

.footerImage {
  height: 2rem;
}

@media only screen and (max-width: 700px) {
  .container {
    margin: 2rem;
    min-height: 70vh;
  }
}

.loadingBox {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 100;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingText {
  background-color: var(--white);
  padding: 1rem 2rem;
  border-radius: 0.2rem;
}

.dotFlashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dotFlashing::before,
.dotFlashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dotFlashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}
.dotFlashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--primary-color);
  }
  50%,
  100% {
    background-color: #d3202934;
  }
}
