@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.body {
  //min-height: 100vh;
  width: 100%;
  //background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  /* display: flex; */
  align-content: center;
  justify-content: center;
}

.body * {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--white);
  border-radius: 10px;
  padding-top: 1rem;
  padding: 1rem;
  //height: calc(100vh - 22rem);
  flex-direction: column;
  margin: 2rem 24rem;
  
}

.consentContainer {
  margin: 2rem 24rem;

} 

.side {
  flex: 1;
  background: url("../../images/hero_bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
}

.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 8rem);
  padding: 4rem 8rem;
}
.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* min-width: 40rem;
  max-width: 90vh; */
}
.headerText {
  text-align: center;
  color: var(--label-black);
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: var(--primary-color);
}
.headerLabelText {
  text-align: start;
  color: var(--label-black);
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
.content {
  padding: 2rem;
  //margin: 1rem;
  margin-bottom:1rem;
  margin-top:1rem;
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 30px;
  //width: 100%;
  max-width: 900px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .content {
   height:100%;
    border-radius:10px;
    width:100%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .container {
    margin: 1rem ;
    height:80%;
  
    border-radius:10px;
  }
}

@media only screen and (max-width: 600px) {
  .consentContainer {
    margin: 6rem 1rem;
    
  
  }

}

@media only screen and (min-width: 601px) {
  .consentContainer {
    margin: 6rem 2rem;
    
  
  }

}

@media only screen and (min-width: 992px) {
  .consentContainer {
    margin: 6rem 8rem;
    
  
  }

}

@media only screen and (min-width: 1200px) {
  .consentContainer {
   margin:6rem 22rem
  
  }

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    margin: 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .container {
    margin: 2rem;
  }
}



/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container {
    margin: 2rem 8rem;
  }
}



/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container {
    margin: 2rem 22rem;
  }
}



/* .container {
    margin: 2rem 1rem;
  }
  .side {
    display: none;
  }
  .main {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    min-width: 40rem;
  max-width: 90vh; 
  }
  .headerText {
    text-align: center;
    color: var(--label-black);
    font-size: 1.5rem;
  }
  .headerNextText {
    text-align: center;
    color: var(--primary-color);;
  }
  .headerLabelText {
    text-align: start;
    color: var(--label-black);
    font-size: 1.3rem;
    margin-top: 1rem;
  }
  .nextStepsCard {
    background: var(--primary-background-transparent);
    border: 1px solid var(--primary-border);
    border-radius: 12px;
    padding: 2rem 1rem;
  }
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 3;
  }
  .logoContainer p {
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
  }
  .footer {
    flex: 1;
    display: flex;
    align-items: end;
  }
  .mainContainer {
    padding: 8rem;
  }
  .secondaryImage {
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .textTitle {
    color: var(--primary-color);;
    font-size: 2rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .textDesc {
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
  }
  .content {
    padding: 2rem;
    margin: 1rem;
    background: var(--primary-background-transparent);
    border: 1px solid var(--primary-border);
    border-radius: 30px;
    width: 80 */
.backdrop {
  /* position: fixed; */
  background: var(--primary-background-transparent);
  height: 100%;
  width: 100%;
  /* padding-left: 10%;
      padding-right: 10%; */
  min-height: 100vh;
}
@media screen and (max-width: 786px) {
  .backdrop {
    background: var(--primary-background-transparent);
    height: 100%;
    width: 100%;
   
    padding-left: 1%;
    padding-right: 1%;
    min-height: 100vh;
  }
}


.nextButton{
  margin:1rem;

}

@media only screen and (max-width: 600px) {
  .nextButton {
    margin:  1rem;
    margin-bottom:0rem
  
  }

}

@media only screen and (min-width: 601px) {
  .nextButton {
    margin: 2rem;
    margin-bottom:0rem
  
  }

}

@media only screen and (min-width: 992px) {
  .nextButton {
    margin:  8rem;
    margin-bottom:0rem
  
  }

}

@media only screen and (min-width: 1200px) {
  .nextButton {
    margin: 22rem;
    margin-bottom:0rem
  
  }

}


