@media screen and (min-width: 641px) {
  .basic {
    display: flex;
    justify-content: space-around;
  }
}
@media screen and (min-width: 1008px) {
  .work {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.holdOnTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  gap: 0.5rem;
}
.holdOnHelperText {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.holdOnHelperTextSmall {
  font-size: 18px;
  margin: 0;
  color: var(--primary-color);
  padding: 0;
}

