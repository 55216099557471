.body {
  background: url("../../../images/hero_bg.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 500px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
}
.main {
  padding: 20px 20px 20px 20px ;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgba(212, 33, 41, 0.15);
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.headerText {
  color: #101828;
  font-size: 1.5rem;
  font-family: 'Outfit';
  margin-left: 10px;
  margin-bottom: 20px;
  
}
.card{
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #D4212926;
  box-shadow: 4px 0px 4px 0px #0000000F;
  border-radius: 12px;
  padding: 18px 20px 18px 20px;
  margin: 0.5rem 0rem;
  min-height: 100px;
  cursor: pointer;
  
}
.description{
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.5;
  margin-left: 12px;
}

@media screen and (max-width:768px) {
  .container{
    width: 90% 
    }
    
    .nextStepsCard {
      background: #fff8f4;
      border: 1px solid rgba(212, 33, 41, 0.15);
      border-radius: 12px;
      padding: 2rem 1rem;
    }
    .card{
      flex-direction: row;
      flex-wrap:nowrap;
    }
    
    .description{
      font-size: 14px;
    }
}

