.disbursed-container{
    background-color: #ffffff;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}


.holdOnTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    gap: 0.5rem;
  }
  .holdOnHelperText {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  .holdOnHelperTextSmall {
    font-size: 18px;
    margin: 0;
    color: var(--primary-color);
    padding: 0;
  }
  