.body {
  min-height: 100vh;
  //background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.body * {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
}

.overallContainer {
  margin-bottom:-5rem;
}


@media screen and (max-width: 600px) {
  .overallContainer {
    width:100%
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* height: 100%; */
  background: var(--white);
  border-radius: 10px;
  padding: 1rem;
  //margin: 2rem 24rem;
  height: calc(100% - 19rem);
  flex-direction: column;
  gap: 2rem;
  /* width: 50%; */
  /* background-color: var(--background-transparent-white); */
  overflow-y: auto;
}

.side {
  flex: 1;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
}
.backdrop {
  /* position: fixed; */
  background: var(--primary-background-transparent);
  height: 100%;
  width: 100%;
  /* padding-left: 10%;
  padding-right: 10%; */
  //min-height: 100vh;
}
@media screen and (max-width: 786px) {
  .backdrop {
    background: var(--primary-background-transparent);
    height: 100%;
    width: 100%;
    //padding-left: 1%;
    //padding-right: 1%;
   //min-height: 100vh;
  }
}

.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 8rem);
  padding: 4rem 8rem;
}
.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* min-width: 40rem;
	max-width: 90vh; */
}
.headerText {
  text-align: center;
  color: var(--label-black);
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: var(--primary-color);
}
.headerLabelText {
  text-align: start;
  color: var(--label-black);
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
/* .content {
  padding: 2rem;
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 30px;
} */
.content {
  width: 100%;
  max-width: 900px;
}
.addressInputGrid {
  /* display: grid; */
  /* direction: ltr; */
  /* grid-template-areas:
    "door door street street"
    "city state pin save"; */
  /* gap: 1rem; */
  display: flex;
  gap: 2.5rem;
 
  flex-direction:column;
  align-items: flex-start;
  justify-content: flex-start;
}
@media screen and (max-width: 786px) {
  .addressInputGrid {
    /* display: grid; */
    /* direction: ltr; */
    /* grid-template-areas:
    "door door street street"
    "city state pin save"; */
    /* gap: 1rem; */
  

    display: block;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 74vw;
  }
}

.employmentDetialsActive {
  background: var(--white);
  color: var(--primary-color);
  padding: 0.4rem;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  text-align: center;
  margin: 0.5em;
  /* border: 1px solid #f9d8d6; */
  cursor: pointer;
  width: 12em;
}

.employmentDetialsInactive {
  //background: var(--primary-background-transparent);
  //background:var(--box-background);
  color: #344054;
  padding: 0.4rem;
  //box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  text-align: center;
  margin: 0.5em;
  /* border: 1px solid #f9d8d6; */
  cursor: pointer;
  width: 12em;
}

.divider {
  border-left: 1px solid var(--primary-border);
  height: 50px;
}

.bankList {
  overflow-y: scroll;
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.bank {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.bankImg {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #e0e0e0;
  background-color: var(--white);
}
.bankContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  border-radius: 1rem;
  min-height: 600px;
  margin: 1rem;
  height: calc(100vh - 10rem);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .container {
    margin: 2rem 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    margin: 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .container {
    margin: 2rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container {
    margin: 2rem 8rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container {
    margin: 2rem 22rem;
  }
}


/*modifications */

.employementDetailsContainer {
    display: flex;
    //flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    background-color:
   
}

@media only screen and (max-width: 600px) {
  .employementDetailsContainer {
    
    flex-direction: column;
    
   
}

}

.employementSubContainer {
  display:flex;
  flex-direction:row;
  
}

@media only screen and (max-width: 600px) {
  .employementSubContainer {
    display:flex;
    flex-direction:column;
    
  }

}