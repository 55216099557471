.consentText{
    font-size: 14px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 50px;
    box-shadow: 4px 4px 10px 4px #0000000F;
    padding: 20px 12px 10px 12px;
    width: 100%;
    border: 1px solid rgba(212, 33, 41, 0.15);
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);


  }

  .title{
    background: #fff3f3;
     border-radius: 12px 12px 0px 0px;
     padding-left:20px;
     padding-top:15px;
     padding-bottom:1px
  }
  .titleText{
   font-size: 18px;
   font-weight: bold;
  }
 .formContainer{
   margin-top: 20px;
 }
 .card1{
   border-radius: 10px;
   border: 1px solid rgba(212, 33, 41, 0.15);
   box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
   margin-bottom: 15px;
 }

 .content {
  width: 95%;
  margin-bottom: 12px;
  
  
}
.addressInputGrid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}
.addressInputGrid2{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
}

