.body {
  min-height: 100vh;
  background: url("../../images/hero_bg.png");
  background-size: cover;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  border-radius: 10px;
  padding: 1rem;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid rgba(212, 33, 41, 0.15);
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
  width: 750px;
  margin: 0 auto;
}

.side {
  flex: 1;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
}
.backdrop {
  background: "#fff3f3";
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 786px) {
  .backdrop {
    height: 100%;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    min-height: 100vh;
  }
  .container{
    width: 90%;
  }
}

.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 8rem);
  padding: 4rem 8rem;
}
.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* min-width: 40rem;
        max-width: 90vh; */
}
.headerText {
  text-align: center;
  color: var(--label-black);
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: var(--primary-color);
}
.headerLabelText {
  text-align: start;
  color: var(--label-black);
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
.content {
  display: flex;
  padding: 1rem;
  /* border: 1px solid #d32028; */
  border-radius: 10px;
  background-color: #fff3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(212, 33, 41, 0.15);
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
}
.planBox {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}
@media screen and (max-width: 786px) {
  .planBox {
    flex: 1;
    display: block;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
}
.advert {
  display: flex;

  /* gap: 10rem; */
  align-items: center;
  border-radius: 12px;
  border: 1px solid #f9d8d6;
  background: #fffcfa;
  justify-content: space-between;
  padding: 1rem;
  margin-left: 1rem;
}

.downloadButton {
  display: flex;
  background: var(--primary-color);
  border-radius: 60px;
  /* width: 9rem; */
  /* padding-left: 15px; */
  padding: 10px 15px;
  color: white;

  cursor: pointer;
}

@media screen and (max-width: 786px) {
  .advert {
    display: block;
    gap: 1rem;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #f9d8d6;
    background: #fffcfa;
    justify-content: space-around;
    padding: 1rem;
  }
}



.prinicpalAmountText {
  font-weight: 600;
  font-size: 1em;
}


@media only screen and (max-width: 600px) {
  .prinicpalAmountText {
    font-weight: 600;
    font-size: 0.8rem;
  }
}


.images {
  height: 1rem;
}

.firstimages {
  height: 3rem;

}

@media only screen and (max-width: 600px) {
  .firstimages {
    height: 1.8rem;
  
  }
}
