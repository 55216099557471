.container{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
}

  .main {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 500px;
  
  }
  
  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .headerText {
    color: #101828;
    font-size: 20px;
    font-family: 'Outfit';

  }
  .card{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #D4212926;
    box-shadow: 4px 0px 4px 0px #0000000F;
    border-radius: 12px;
    padding: 18px 20px 18px 20px;
    margin: 0.5rem 0rem;
    min-height: 100px;
    cursor: pointer;
    
  }
  .description{
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.5;
    margin-left: 12px;
  }

  .listContainer{
    margin-top:-13px;
    position: absolute;
    z-index:999;
    background-color: #ffffff;
    width:300px;
    border:1px solid #DbDbDB;
    border-radius:0.5em;
    max-height:250px;
    overflow-y: auto;
  }
  .radioContainer{
    display:flex;
    justify-content:space-between;
    width:400px;
    flex-wrap:wrap;
    margin-bottom: 10px;
  }

  .btnContainer{
     margin-top:2px;
     align-self:center;
    width:30%
  
  }
  .studentFieldsContainer{
    display: flex;
    flex-direction: column;
  }
  
  @media screen and (max-width:768px) {
    .main{
      align-items: center;
    }
      .headerText{
        font-size: 18px;
      }
      .card{
        width: 280px;
        padding: 0px 5px 0px 5px;
      }
      .card img{
        width: 40px;
      }
      .description{
        font-size: 14px;
      }
      .btnContainer{
        text-align: center;
      }
  }
