
.container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  
}
.main {
  width: 500px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #D4212926;
  box-shadow: 4px 0px 4px 0px #0000000F;
}
.image{
  width: 40px;
  height:40px;
   margin-bottom: 0.5rem
}
.headerText{
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  
}
.cardTitles{
  color: #737373;
  font-size: 14px;
}
@media screen and (max-width:768px) {
  .main{
    width: 100%;
  }
  .image{
    width: 30px;
  }
  .headerText{
    font-size: 16px;
  }
  .cardTitles{
    font-size: 12px;
  }
}



.Header {
  display: inline-flex;
  justify-content: space-between;
}
