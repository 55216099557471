.body {
    background: url("../../../images/hero_bg.png");
    background-size: cover;
  }
  
  .body * {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
  }
  
  
  
  
  @media screen and (max-width: 600px) {
    .overallContainer {
      width:100%
    }
  }
  
  .container {
    background: var(--white);
    border-radius: 10px;
    padding: 20px 18px 20px 18px;
    margin: 0 auto;
    border: 1px solid rgba(212, 33, 41, 0.15);
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
    width: 600px;
  }
  .description{
    font-size: 18px;
    text-align: center;
  }
  .title{
    background: #fff3f3;
     border-radius: 12px 12px 0px 0px;
     padding-left:20px;
     padding-top:15px;
     padding-bottom:1px;
     margin-bottom: 10px;
  }
  .headerText {
    font-size: 20px;
    font-weight: bold;
  }
  @media screen and (max-width:768px) {
    .container{
      width: 90%;
    }
    .headerText{
      font-size: 16px;
    }
    .description{
      font-size: 14px;
    }
    
  }
  .side {
    flex: 1;
    background: url("../../../images/hero_bg.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 0px 30px 30px 0px;
  }
  .backdrop {
    background: "#fff3f3";
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sideContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 8rem);
    padding: 4rem 8rem;
  }
  .main {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* min-width: 40rem;
      max-width: 90vh; */
  }
 
  .headerNextText {
    text-align: center;
    color: var(--primary-color);
  }
  .headerLabelText {
    text-align: start;
    color: var(--label-black);
    font-size: 1.3rem;
    margin-top: 1rem;
  }
  .nextStepsCard {
    background: var(--primary-background-transparent);
    border: 1px solid var(--primary-border);
    border-radius: 12px;
    padding: 2rem 1rem;
  }
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 3;
  }
  .logoContainer p {
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
  }
  .footer {
    flex: 1;
    display: flex;
    /* flex-direction: column; */
    align-items: end;
  }
  .mainContainer {
    padding: 8rem;
  }
  .secondaryImage {
    position: fixed;
    bottom: 0;
    right: 0;
  }
  
  .textTitle {
    color: var(--primary-color);
    font-size: 2rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .textDesc {
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
  }
  /* .content {
    padding: 2rem;
    background: var(--primary-background-transparent);
    border: 1px solid var(--primary-border);
    border-radius: 30px;
  } */
  .content {
    width: 100%;
    /* max-width: 900px; */
  }
 
  .addressInputGrid {
    /* display: grid; */
    /* direction: ltr; */
    /* grid-template-areas:
      "door door street street"
      "city state pin save"; */
    /* gap: 1rem; */
    display: flex;
    gap: 2.5rem;
   
    flex-direction:column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media screen and (max-width: 786px) {
    .addressInputGrid {
      /* display: grid; */
      /* direction: ltr; */
      /* grid-template-areas:
      "door door street street"
      "city state pin save"; */
      /* gap: 1rem; */
    
  
      display: block;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      width: 74vw;
    }
  }
  
  .employmentDetialsActive {
    background: var(--white);
    color: var(--primary-color);
    padding: 0.4rem;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 6px;
    text-align: center;
    margin: 0.5em;
    /* border: 1px solid #f9d8d6; */
    cursor: pointer;
    width: 12em;
  }
  
  .employmentDetialsInactive {
    /* //background: var(--primary-background-transparent);
    //background:var(--box-background); */
    color: #344054;
    padding: 0.4rem;
    /* //box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),0px 1px 2px rgba(16, 24, 40, 0.06); */
    border-radius: 6px;
    text-align: center;
    margin: 0.5em;
    /* border: 1px solid #f9d8d6; */
    cursor: pointer;
    width: 12em;
  }
  
  .divider {
    border-left: 1px solid var(--primary-border);
    height: 50px;
  }
  
  .bankList {
    overflow-y: scroll;
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0.5rem 0;
  }

  @media only screen and (max-width: 600px)  {
    .bankList {
      overflow: scroll !important;
      
      
    }

  }




  .bank {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  
  .bankImg {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #e0e0e0;
    background-color: var(--white);
  }
  .bankContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1rem;
    height: 100%;
  }
.bankSelectCard{
  background-color: #ffffff;
  border-radius: 12px;
  width: 500px;
  height:75%;
}


@media screen and (max-width:768px){
  .bankSelectCard{
    width: 90%;
  }
  
}
.aaContainer{
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 18px 20px 18px;
  margin: 10px;
}
  .accountAggregatorbankContainer {
    display: flex;
    flex-direction: column;

  }
/* 
  @media only screen and (min-width: 600px) {
    .accountAggregatorbankContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: rem;
      border-radius: 1rem;
      min-height: 600px;
      margin: 1rem;
      height: calc(100vh - 10rem);
  
    }


  } */



  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    /* padding: 0.5rem 2rem; */
    padding-top:2rem;
    align-items: center;
  }

  @media only screen and (min-width: 600px)  {
    .buttonsContainer {
      display: flex;
    justify-content: space-between;
    /* padding: 0.5rem 2rem; */
    align-items: center;
      
    }

  }

  .uploadContainer{
    width: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 18px 0px 18px;
  
  }
  .uploadBankContainer {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    height: 350px;
  }

  @media  screen and (max-width: 768px) {
    .uploadContainer{
      width: 90%;
    }
  
  }

  

  

  /*modifications */
  
  .employementDetailsContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      background-color:#f0f0f0
     
  }
  
  @media only screen and (max-width: 600px) {
    .employementDetailsContainer {
      
      flex-direction: column;
      
     
  }
  
  }
  
  .employementSubContainer {
    display:flex;
    flex-direction:row;
    
  }
  
  @media only screen and (max-width: 600px) {
    .employementSubContainer {
      display:flex;
      flex-direction:column;
      
    }
  
  }



  .text {
    font-weight: 500;
    font-size: 16px;
    margin-left: 10px;
    margin-top: 10px;
  }

  @media  screen and (max-width: 768px) {
    .text {  
      font-size: 14px;
    }
  
  }

  .textImageContainer {
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    padding: 1rem 1rem ;
    border: 1px solid #D4212926;
  }

  @media  screen and (max-width: 768px) {
    .textImageContainer {
      padding: 0.5rem 0.5rem;
    }
  
  }  


  .image {
    height: 30px;
    width: 30px;
  }


  @media  screen and (max-width: 768px) {
    .image {
      width: 25px;
    }
  
  }
/* 
  @media only screen and (max-width: 600px) {

  .bgUploadContainer {
    width:100% !important;
    
    padding:1rem !important;
    

  }
} */