.body {
    background: url("../../../images/hero_bg.png");
    background-size: cover;

  } 
  
  .body * {
    font-family: "Outfit", sans-serif  !important;
    font-optical-sizing: auto;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 30px 10px;
    padding-bottom: 10px;
    margin: 10px auto;
    width: 600px;
    background-color: #ffffff;
    border: 1px solid rgba(212, 33, 41, 0.15);
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
  }
  @media screen and (max-width: 768px){
    .container{
      width: 95%;
    }
  }
  .title{
    background: #fff3f3;
     border-radius: 12px 12px 0px 0px;
     padding-left:20px;
     padding-top:15px;
     padding-bottom:1px
  }
  .titleText{
    font-size: 18px;
    font-weight: bold;
   }





  .side {
    flex: 1;
    background: url("../../../images/hero_bg.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 0px 30px 30px 0px;
  }
  .backdrop {
    background: "#fff3f3";
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media screen and (max-width: 786px) {
    .backdrop {
      background: "#fff3f3";
      height: 100%;
      width: 100%;
      padding-left: 1%;
      padding-right: 1%;
      min-height: 100vh;
    }
  }
  
  .sideContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 8rem);
    padding: 4rem 8rem;
  }
  .main {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* min-width: 40rem;
      max-width: 90vh; */
  }
  .headerText {
    text-align: center;
    color: var(--label-black);
    font-size: 1.5rem;
  }
  .headerNextText {
    text-align: center;
    color: var(--primary-color);
  }
  .headerLabelText {
    text-align: start;
    color: var(--label-black);
    font-size: 1.3rem;
    margin-top: 1rem;
  }
  .nextStepsCard {
    background: var(--primary-background-transparent);
    border: 1px solid var(--primary-border);
    border-radius: 12px;
    padding: 2rem 1rem;
  }
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 3;
  }
  .logoContainer p {
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
  }
  .footer {
    flex: 1;
    display: flex;
    /* flex-direction: column; */
    align-items: end;
  }
  .mainContainer {
    padding: 8rem;
  }
  .secondaryImage {
    position: fixed;
    bottom: 0;
    right: 0;
  }
  
  .textTitle {
    color: var(--primary-color);
    font-size: 2rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .textDesc {
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
  }
  /* .content {
    padding: 2rem;
    background: var(--primary-background-transparent);
    border: 1px solid var(--primary-border);
    border-radius: 30px;
  } */
  .content {
    width: 95%;
    box-shadow: 4px 0px 30px 2px rgba(0, 0, 0, 0.06);
  }
  .addressInputGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inputFieldCard{
    width: 70%;
    margin-bottom: 12px;
    margin-left: 20px;
  }
  @media screen and (max-width: 786px) {
    .inputFieldCard{
      width: 90%;
    }
  }
  .employmentDetialsActive {
    background-color: #d32028;
    color: #fff;
    padding: 0.4rem;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 6px;
    text-align: center;
    margin: 1.2em;
    /* border: 1px solid #f9d8d6; */
    cursor: pointer;
    width: 12em;
  }
  
  .employmentDetialsInactive {
    background: var(--primary-background-transparent);
    color: #344054;
    padding: 0.4rem;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 6px;
    text-align: center;
    margin: 1.2em;
    /* border: 1px solid #f9d8d6; */
    cursor: pointer;
    width: 12em;
  }
  
  .divider {
    border-left: 1px solid var(--primary-border);
    height: 50px;
  }
  
  .bankList {
    overflow-y: scroll;
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0.5rem 0;
  }
  .bank {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  
  .bankImg {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #e0e0e0;
    background-color: var(--white);
  }
  .bankContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem;
    border-radius: 1rem;
    min-height: 600px;
    margin: 1rem;
    height: calc(100vh - 10rem);
  }
  
 