@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.body {
  background: url("../../../images/hero_bg.png");
  background-size: cover;

}

.body * {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;

}

.backdrop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  
}
@media screen and (max-width: 786px) {
  .backdrop {    
    height: 100%;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
  }
}


.container {
  background-color: #ffffff;
  width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 0 auto;
  padding:20px 18px 10px 18px;
  border: 1px solid rgba(212, 33, 41, 0.15);
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
}
@media screen and (max-width: 786px) {
  .container {
    width: 95%;
  }
  
}
.content {
  padding: 10px 12px 10px 12px;
  margin: 1rem;
  /* border: 1px solid #d32028; */
  border-radius: 10px;
  width: 95%;
  box-shadow: 2px 4px 41px 10px #0000000F;
}
.title{
  background: #fff3f3;
   border-radius: 12px 12px 0px 0px;
   padding-left:20px;
   padding-top:15px;
   padding-bottom:1px;
   margin-bottom: 10px;
}
.headerText {
  color: #101828;
  font-size: 20px;
  font-family: 'Outfit';
  margin-bottom: 12px;
  font-weight: bold;
}

.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width:500px;
}

.headerNextText {
  text-align: center;
  color: var(--primary-color);
}
.listContainer{
  margin-top:-13px;
  position: absolute;
  z-index:999;
  background-color: #ffffff;
  width:300px;
  border:1px solid #DbDbDB;
  border-radius:0.5em;
  max-height:250px;
  overflow-y: auto;
}

.studentFieldsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}


.headerLabelText {
  text-align: start;
  color: var(--label-black);
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}

